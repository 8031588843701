import './App.css';
import Todos from './components/Todos';
function App() {
  return (
    <div className="App">
      <h1>TODO App</h1>
      <Todos></Todos>
    </div>
  );
} 

export default App;
